.news-share-container {
	.twitter-button,
	.facebook-button {
		transform: scale(1);
		transition: transform 0.2s ease-in-out;
	}

	&.open {
		.share-button {
			transform: scale(0.7);
		}

		.share-button,
		.facebook-button,
		.twitter-button {
			transition: transform 0.2s ease-in-out;
		}

		.facebook-button {
			transform: scale(1) translateX(50px);
			transition-delay: 0.1s;
		}

		.twitter-button {
			transform: scale(1) translateX(100px);
			transition-delay: 0.15s;
		}
	}
}
